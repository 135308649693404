var OFFSET = 8;
var getPosition = function getPosition(triggerRectParam, tooltipRectParam, position) {
  var triggerRect = {
    bottom: Number(triggerRectParam.bottom),
    height: Number(triggerRectParam.height),
    left: Number(triggerRectParam.left),
    right: Number(triggerRectParam.right),
    top: Number(triggerRectParam.top),
    width: Number(triggerRectParam.width)
  };
  var tooltipRect = {
    bottom: Number(tooltipRectParam.bottom),
    height: Number(tooltipRectParam.height),
    left: Number(tooltipRectParam.left),
    right: Number(tooltipRectParam.right),
    top: Number(tooltipRectParam.top),
    width: Number(tooltipRectParam.width)
  };
  var collisions = {
    top: triggerRect.top - tooltipRect.height < 0,
    right: window.innerWidth < triggerRect.left + tooltipRect.width,
    bottom: window.innerHeight < triggerRect.bottom + tooltipRect.height + OFFSET,
    left: triggerRect.left - tooltipRect.width < 0
  };
  var directionRight = collisions.right && !collisions.left;
  var directionUp = collisions.bottom && !collisions.top;
  if (position === 'right') {
    return {
      left: collisions.right ? triggerRect.right - triggerRect.width - tooltipRect.width - OFFSET + window.pageXOffset : triggerRect.left + triggerRect.width + OFFSET + window.pageXOffset,
      top: directionUp ? triggerRect.top - OFFSET - tooltipRect.height + window.pageYOffset : triggerRect.top + window.pageYOffset
    };
  }
  if (position === 'left') {
    return {
      left: collisions.left ? triggerRect.left + triggerRect.width + OFFSET + window.pageXOffset : triggerRect.right - triggerRect.width - tooltipRect.width - OFFSET + window.pageXOffset,
      top: directionUp ? triggerRect.top - OFFSET - tooltipRect.height + window.pageYOffset : triggerRect.top + window.pageYOffset
    };
  }
  if (position === 'top') {
    return {
      left: directionRight ? triggerRect.right - tooltipRect.width + window.pageXOffset : triggerRect.left + window.pageXOffset,
      top: collisions.top ? triggerRect.top + OFFSET + triggerRect.height + window.pageYOffset : triggerRect.top - OFFSET - tooltipRect.height + window.pageYOffset
    };
  }
  return {
    left: directionRight ? triggerRect.right - tooltipRect.width + window.pageXOffset : triggerRect.left + window.pageXOffset,
    top: directionUp ? triggerRect.top - OFFSET - tooltipRect.height + window.pageYOffset : triggerRect.top + OFFSET + triggerRect.height + window.pageYOffset
  };
};
export default getPosition;