export var LIGHT_LINKS = {
  level1: 'link-light link-level2',
  level2: 'link-light link-level3',
  level3: 'link-light link-level3-gray',
  'button-main': 'link-light link-level1',
  button: 'link-light link-level1-gray',
  body1: 'link-light link-level4 font-body1',
  body2: 'link-light link-level4 font-body2',
  body3: 'link-light link-level4 font-body3',
  body4: 'link-light link-level4 font-body4',
  legals: 'link-light link-level4 font-legals'
};
export var DARK_LINKS = {
  level1: 'link-dark link-level2',
  level2: 'link-dark link-level3',
  level3: 'link-dark link-level3-gray',
  'button-main': 'link-dark link-level1',
  button: 'link-dark link-level1-gray',
  body1: 'link-dark link-level4-dark font-body1',
  body2: 'link-dark link-level4-dark font-body2',
  body3: 'link-dark link-level4-dark font-body3',
  body4: 'link-dark link-level4-dark font-body4',
  legals: 'link-dark link-level4-dark font-legals'
};