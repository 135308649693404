/**
 * Gets the current user device SO by parsing the `userAgent`.
 * This API is not compatible with SSR.
 * Currently support for `Android`, `iOS`, Windows`, `Mac` and `WindowsPhone`
 */
var getDeviceSO = function getDeviceSO() {
  var _navigator = navigator,
    userAgent = _navigator.userAgent,
    platform = _navigator.platform,
    maxTouchPoints = _navigator.maxTouchPoints;
  var hasAndroidPattern = /Android/i.test(userAgent);
  var isWindowsPhone = /Windows Phone/i.test(userAgent);

  // Includes isWindowsPhone validation because Windows devices UA includes 'Android' keyword.
  var isAndroid = hasAndroidPattern && !isWindowsPhone;
  var isWindows = ['Win32', 'Win64', 'Windows', 'WinCE'].includes(platform);

  // The !window.MSStream is to not incorrectly detect IE11. New platform & maxTouchPoints validations supports iPadOS.
  var isIOS = (/iPad|iPhone|iPod/.test(userAgent) || platform === 'MacIntel' && maxTouchPoints > 1) &&
  // @ts-ignore
  !window.MSStream;
  var isMac = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].includes(platform) && !isIOS;
  return {
    isAndroid: isAndroid,
    isIOS: isIOS,
    isMac: isMac,
    isWindows: isWindows,
    isWindowsPhone: isWindowsPhone
  };
};
export default getDeviceSO;