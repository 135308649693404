import { useEffect } from 'react';
import Hls from 'hls.js';
import { usePlaybackContext } from "../../Playback";
/**
 * This hook is used to listen to the LEVEL_LOADING event fired by hls.js,
 * needed to validate the loading state.
 *
 * LEVEL_LOADING: fired when a level playlist loading starts. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useLevelLoadingEvent = function useLevelLoadingEvent(_ref) {
  var hls = _ref.hls;
  var _usePlaybackContext = usePlaybackContext(),
    onLoadStart = _usePlaybackContext.onLoadStart;
  useEffect(function () {
    if (!hls) {
      return;
    }
    hls.on(Hls.Events.LEVEL_LOADING, onLoadStart);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.LEVEL_LOADING, onLoadStart);
    };
  }, [hls, onLoadStart]);
};
export default useLevelLoadingEvent;