import { css } from 'styled-components';
var createCommonGradientCss = function createCommonGradientCss(_ref) {
  var visibleUi = _ref.visibleUi;
  return css(["\n  opacity: ", ";\n  transition: ", ";\n"], visibleUi ? 0.7 : 0, function (_ref2) {
    var theme = _ref2.theme;
    return theme.transition('opacity', 300);
  });
};
export var createTopGradientCss = function createTopGradientCss(_ref3) {
  var visibleUi = _ref3.visibleUi,
    isMd = _ref3.isMd;
  var commonGradientCss = createCommonGradientCss({
    visibleUi: visibleUi
  });
  if (isMd) {
    return css(["\n      ", "\n      height: 204px;\n      background-image: linear-gradient(\n        180deg,\n        ", " 0%,\n        rgba(24, 27, 50, 0) 100%\n      );\n    "], commonGradientCss, function (_ref4) {
      var theme = _ref4.theme;
      return theme.colors.base.main;
    });
  }
  return css(["\n    ", "\n    height: 60%;\n    background-image: linear-gradient(\n      180deg,\n      ", " 0%,\n      rgba(24, 27, 50, 0) 96.88%\n    );\n  "], commonGradientCss, function (_ref5) {
    var theme = _ref5.theme;
    return theme.colors.base.main;
  });
};
export var createBottomGradientCss = function createBottomGradientCss(_ref6) {
  var visibleUi = _ref6.visibleUi,
    isMd = _ref6.isMd;
  var commonGradientCss = createCommonGradientCss({
    visibleUi: visibleUi
  });
  if (isMd) {
    return css(["\n      ", "\n      height: 204px;\n      background-image: linear-gradient(\n        180deg,\n        ", " 19.79%,\n        rgba(24, 27, 50, 0) 87.5%\n      );\n      transform: rotate(-180deg);\n    "], commonGradientCss, function (_ref7) {
      var theme = _ref7.theme;
      return theme.colors.base.main;
    });
  }
  return css(["\n    ", "\n    height: 60%;\n    background-image: linear-gradient(\n      180deg,\n      ", " 0%,\n      rgba(24, 27, 50, 0) 96.88%\n    );\n    transform: rotate(-180deg);\n  "], commonGradientCss, function (_ref8) {
    var theme = _ref8.theme;
    return theme.colors.base.main;
  });
};