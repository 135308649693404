import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { VIDEO_SPEEDS_BY_VALUE } from "../../constants";
export var getPlaybackReducerInitialState = function getPlaybackReducerInitialState(_ref) {
  var defaultSpeed = _ref.defaultSpeed,
    inactivity = _ref.inactivity;
  return {
    currentSource: undefined,
    loading: false,
    currentTime: 0,
    currentProgress: 0,
    duration: 0,
    currentSpeed: VIDEO_SPEEDS_BY_VALUE[defaultSpeed || 1] || VIDEO_SPEEDS_BY_VALUE[1],
    isIdle: false,
    visibleIdleScreen: false,
    ignoreInactivity: !!(inactivity && inactivity.ignore),
    visibleSkip: false,
    skipDismissed: false
  };
};
var playbackReducer = function playbackReducer(state, event) {
  switch (event.type) {
    case 'CHANGE_LOADING_STATE':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: event.loading
      });
    case 'CHANGE_CURRENT_SOURCE':
      return _objectSpread(_objectSpread({}, getPlaybackReducerInitialState({})), {}, {
        currentSpeed: state.currentSpeed,
        isIdle: state.isIdle,
        ignoreInactivity: state.ignoreInactivity,
        currentSource: event.newSource
      });
    case 'CHANGE_SPEED':
      return _objectSpread(_objectSpread({}, state), {}, {
        currentSpeed: event.newSpeed
      });
    case 'CHANGE_DURATION':
      return _objectSpread(_objectSpread({}, state), {}, {
        duration: event.duration
      });
    case 'CHANGE_UI_STATE':
      return _objectSpread(_objectSpread({}, state), event);
    case 'CHANGE_PROGRESS':
      return _objectSpread(_objectSpread({}, state), {}, {
        currentTime: event.currentTime,
        currentProgress: event.currentProgress
      });
    case 'TOGGLE_IGNORE_INACTIVITY':
      return _objectSpread(_objectSpread({}, state), {}, {
        ignoreInactivity: !state.ignoreInactivity
      });
    default:
      return state;
  }
};
export default playbackReducer;