import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { getDisabledSubtitlesOption } from "./utils";
export var SUBTITLE_REDUCER_INITIAL_STATE = {
  subtitleOptions: undefined,
  subtitleOptionsType: 'none',
  currentSubtitleLang: undefined,
  currentExternalSubtitles: undefined
};
var subtitleReducer = function subtitleReducer(state, event) {
  switch (event.type) {
    case 'SETUP_SUBTITLE_OPTIONS':
      return _objectSpread(_objectSpread({}, state), {}, {
        subtitleOptions: event.subtitleOptions,
        subtitleOptionsType: event.subtitleOptionsType,
        currentSubtitleLang: event.currentSubtitleLang
      });
    case 'CHANGE_CURRENT_SUBTITLE_LANG':
      return _objectSpread(_objectSpread({}, state), {}, {
        currentSubtitleLang: event.currentSubtitleLang
      });
    case 'CHANGE_CURRENT_EXTERNAL_SUBTITLES':
      return _objectSpread(_objectSpread({}, state), {}, {
        currentExternalSubtitles: event.currentExternalSubtitles,
        currentSubtitleLang: event.currentExternalSubtitles ? state.currentSubtitleLang : getDisabledSubtitlesOption(event.t)
      });
    default:
      return state;
  }
};
export default subtitleReducer;