import { useEffect } from 'react';
import Hls from 'hls.js';
import { VIDEO_MIME_TYPES } from "../../../constants";
import { usePlaybackContext } from "../../Playback";
/**
 * This hook is used to listen to the FRAG_LOADED event fired by hls.js,
 * needed as a fallback to validate if a source was loaded and is ready to play.
 *
 * FRAG_LOADED: fired when a fragment loading is completed. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useFragLoadedEvent = function useFragLoadedEvent(_ref) {
  var hls = _ref.hls,
    resetErrorState = _ref.resetErrorState;
  var _usePlaybackContext = usePlaybackContext(),
    onReady = _usePlaybackContext.onReady;
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onFragmentLoaded = function onFragmentLoaded(_, data) {
      var _hls$media;
      var videoTotalDuration = hls === null || hls === void 0 ? void 0 : (_hls$media = hls.media) === null || _hls$media === void 0 ? void 0 : _hls$media.duration;

      // Trigger onReady when a fragment loads in case the ready state was not previously set
      if (videoTotalDuration) {
        var currentHlsLevel = (hls === null || hls === void 0 ? void 0 : hls.levels) && hls.levels[data.frag.level];
        var currentQualityValue = currentHlsLevel ? currentHlsLevel.height : undefined;
        resetErrorState();
        onReady({
          duration: videoTotalDuration,
          type: VIDEO_MIME_TYPES.HLS,
          quality: currentQualityValue === null || currentQualityValue === void 0 ? void 0 : currentQualityValue.toString()
        });
      }
    };

    // We just need to listen to this event once to validate if the video is ready to play
    hls.once(Hls.Events.FRAG_LOADED, onFragmentLoaded);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.FRAG_LOADED, onFragmentLoaded);
    };
  }, [hls, onReady, resetErrorState]);
};
export default useFragLoadedEvent;