import { useEffect } from 'react';
import Hls from 'hls.js';
import { usePlaybackContext } from "../../Playback";
/**
 * This hook is used to listen to the LEVEL_SWITCHED event fired by hls.js,
 * needed to check the new quality loaded.
 *
 * LEVEL_SWITCHED: fired when a level switch is effective. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useLevelSwitchedEvent = function useLevelSwitchedEvent(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    onQualityChangeProp = _ref.onQualityChange,
    withLowQualityWarning = _ref.withLowQualityWarning,
    currentQuality = _ref.currentQuality,
    currentQualityWarning = _ref.currentQualityWarning;
  var _usePlaybackContext = usePlaybackContext(),
    currentTime = _usePlaybackContext.currentTime,
    currentProgress = _usePlaybackContext.currentProgress;
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onQualityChanged = function onQualityChanged(_, data) {
      var currentHlsLevel = (hls === null || hls === void 0 ? void 0 : hls.levels) && hls.levels[data.level];
      var currentQualityValue = currentHlsLevel ? currentHlsLevel.height : undefined;
      if (currentQualityValue) {
        // Save the real quality (needed in case the option selected is Auto)
        dispatch({
          type: 'CHANGE_QUALITY_STATE',
          realCurrentQualityValue: currentQualityValue
        });
        // Validate if a warning should be shown on Auto quality mode
        if (withLowQualityWarning) {
          var maximumLowQuality = withLowQualityWarning.maximumLowQuality,
            minimumVideoProgress = withLowQualityWarning.minimumVideoProgress,
            onWarningShown = withLowQualityWarning.onWarningShown;
          var lowQuality = currentQualityValue <= maximumLowQuality;
          var shouldRenderWarning = currentQualityWarning !== 'low-quality' && (currentQuality === null || currentQuality === void 0 ? void 0 : currentQuality.value) === -1 && lowQuality && currentProgress >= minimumVideoProgress;
          if (shouldRenderWarning) {
            dispatch({
              type: 'CHANGE_QUALITY_STATE',
              hasLowQuality: lowQuality,
              currentQualityWarning: 'low-quality'
            });
            if (onWarningShown) {
              onWarningShown({
                currentTime: currentTime,
                currentProgress: currentProgress,
                quality: currentQualityValue
              });
            }
          } else {
            dispatch({
              type: 'CHANGE_QUALITY_STATE',
              hasLowQuality: lowQuality
            });
          }
        }
        // Validate callback
        if (onQualityChangeProp) {
          onQualityChangeProp(currentQualityValue.toString());
        }
      }
    };
    hls.on(Hls.Events.LEVEL_SWITCHED, onQualityChanged);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.LEVEL_SWITCHED, onQualityChanged);
    };
  },
  // Ignoring onQualityChangeProp, assuming it doesn't change after the first render.
  // Ignoring withLowQualityWarning object because we're only including the dynamic properties.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [hls, dispatch, currentProgress, currentTime, currentQuality, withLowQualityWarning === null || withLowQualityWarning === void 0 ? void 0 : withLowQualityWarning.maximumLowQuality, withLowQualityWarning === null || withLowQualityWarning === void 0 ? void 0 : withLowQualityWarning.minimumVideoProgress, currentQualityWarning]);
};
export default useLevelSwitchedEvent;