export var calculateBufferedRanges = function calculateBufferedRanges(videoElement) {
  if (videoElement !== null && videoElement !== void 0 && videoElement.buffered) {
    var bufferedTime = 0;
    var bufferedRanges = Array.from({
      length: videoElement.buffered.length
    }).map(function (_, index) {
      var start = 0;
      var end = 0;
      if (videoElement !== null && videoElement !== void 0 && videoElement.buffered) {
        try {
          start = videoElement ? videoElement.buffered.start(index) : 0;
          end = videoElement ? videoElement.buffered.end(index) : 0;
        } catch (e) {
          /**
           * A DOMException is thrown if the specified index doesn't correspond to an existing range.
           * This situation can happen if this function is trying to be executed after the video has changed.
           */
        }
      }
      bufferedTime = bufferedTime + end - start;
      return {
        start: start,
        end: end
      };
    });
    return {
      bufferedTime: bufferedTime,
      bufferedRanges: bufferedRanges
    };
  }
  return {
    bufferedTime: 0,
    bufferedRanges: []
  };
};