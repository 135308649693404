export var MIN_TOAST_TIME = 4;
export var MAX_TOAST_TIME = 10;
export var TOAST_COLORS = {
  informative: {
    text: {
      light: 'tw-text-info-600',
      dark: ''
    },
    leftBorder: {
      light: 'tw-bg-info-500',
      dark: 'dark:tw-bg-info-300'
    },
    otherBordersDark: 'dark:tw-border-info-300'
  },
  success: {
    text: {
      light: 'tw-text-success-500',
      dark: ''
    },
    leftBorder: {
      light: 'tw-bg-success-500',
      dark: 'dark:tw-bg-success-300'
    },
    otherBordersDark: 'dark:tw-border-success-400'
  },
  warning: {
    text: {
      light: 'tw-text-on-hold-500',
      dark: ''
    },
    leftBorder: {
      light: 'tw-bg-on-hold-500',
      dark: 'dark:tw-bg-on-hold-300'
    },
    otherBordersDark: 'dark:tw-border-on-hold-300'
  },
  error: {
    text: {
      light: 'tw-text-alert-500',
      dark: ''
    },
    leftBorder: {
      light: 'tw-bg-alert-500',
      dark: 'dark:tw-bg-alert-300'
    },
    otherBordersDark: 'dark:tw-border-alert-300'
  }
};