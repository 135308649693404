import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useEffect } from 'react';
import Hls from 'hls.js';
import { useTranslation } from "../../../hooks";
import { normalizeVideoQualities } from "../utils";
/**
 * This hook is used to listen to the MANIFEST_PARSED event fired by hls.js,
 * needed to validate the available qualities from the hls manifest to set the options list and current quality.
 *
 * MANIFEST_PARSED: fired after manifest has been parsed. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useManifestParsedEvent = function useManifestParsedEvent(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    defaultQuality = _ref.defaultQuality,
    currentQuality = _ref.currentQuality;
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onManifestParsed = function onManifestParsed(_, data) {
      var normalizedQualityData = normalizeVideoQualities({
        data: data,
        t: t,
        defaultQuality: defaultQuality,
        currentQuality: currentQuality
      });
      if (hls && normalizedQualityData.currentQuality) {
        hls.currentLevel = normalizedQualityData.currentQuality.value;
      }
      dispatch(_objectSpread({
        type: 'CHANGE_QUALITY_STATE'
      }, normalizedQualityData));
    };
    hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.MANIFEST_PARSED, onManifestParsed);
    };
    // Ignoring t function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls, dispatch, defaultQuality, currentQuality]);
};
export default useManifestParsedEvent;