export var getUserTagLabel = function getUserTagLabel(_ref) {
  var type = _ref.type,
    lang = _ref.lang;
  switch (type) {
    case 'prof':
      return {
        userLabel:
        // eslint-disable-next-line no-nested-ternary
        lang === 'pt' ? 'professor' : lang === 'en' ? 'teacher' : 'profesor',
        textColor: 'text-primary-lighter'
      };
    case 'mentor':
      return {
        userLabel: 'mentor',
        textColor: 'text-primary-lighter'
      };
    case 'mentor-star':
      return {
        userLabel: 'crehana star',
        textColor: 'text-primary-lighter'
      };
    case 'premium-student':
      return {
        userLabel: 'premium estudiantil',
        textColor: 'text-orange-light'
      };
    case 'premium':
      return {
        userLabel: 'premium',
        textColor: 'text-orange-light'
      };
    case 'premium-lite':
      return {
        userLabel: 'premium lite',
        textColor: 'text-orange-light'
      };
    case 'pro':
      return {
        userLabel: 'pro',
        textColor: 'text-orange-light'
      };
    case 'plus':
      return {
        userLabel: 'plus',
        textColor: 'text-orange-light'
      };
    case 'b2b':
      return {
        userLabel: 'b2b',
        textColor: 'text-yellow-light'
      };
    case 'b2b-admin':
      return {
        userLabel: 'b2b admin',
        textColor: 'text-yellow-light'
      };
    case 'scholarship':
      return {
        userLabel:
        // eslint-disable-next-line no-nested-ternary
        lang === 'pt' ? 'bolsista' : lang === 'en' ? 'scholarship' : 'becado',
        textColor: 'text-red-light'
      };
    default:
      return {
        userLabel: 'staff',
        textColor: 'text-primary-lighter'
      };
  }
};