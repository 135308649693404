import { useEffect } from 'react';
import Hls from 'hls.js';
import { LANG_LABEL_BY_KEY } from "../../../constants";
import { useTranslation } from "../../../hooks";
/**
 * This hook is used to listen to the AUDIO_TRACKS_UPDATED event fired by hls.js,
 * needed to validate the available audios from the hls manifest to set the options list and current audio.
 *
 * AUDIO_TRACKS_UPDATED: fired to notify that audio track lists has been updated. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useAudioTracksUpdatedEvent = function useAudioTracksUpdatedEvent(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    defaultAudioLang = _ref.defaultAudioLang;
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onAudioTracksReady = function onAudioTracksReady(_, data) {
      var audioTracks = data.audioTracks.filter(function (audio) {
        return audio.lang && Object.keys(LANG_LABEL_BY_KEY).includes(audio.lang);
      });
      if (audioTracks.length > 1) {
        dispatch({
          type: 'CHANGE_AUDIO_STATE',
          audios: audioTracks.map(function (audio) {
            return {
              label: t(LANG_LABEL_BY_KEY[audio.lang]),
              value: audio.id
            };
          })
        });
        if (defaultAudioLang) {
          var defaultAudio = audioTracks.find(function (audio) {
            return audio.lang === defaultAudioLang;
          });
          if (hls && defaultAudio) {
            hls.audioTrack = defaultAudio.id;
          }
        }
      }
    };
    hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, onAudioTracksReady);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.AUDIO_TRACKS_UPDATED, onAudioTracksReady);
    };
    // Ignoring t function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls, dispatch, defaultAudioLang]);
};
export default useAudioTracksUpdatedEvent;