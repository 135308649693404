export var REGEX_EMAIL = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
export var REGEX_URL = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Z]?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^()\s,]*)?/g;
export var REGEX_URL_BEGIN = /^(https?:\/\/(www.)?)/;
export var REGEX_FILE = /^[a-z0-9]+([-]{1}[a-z0-9]+)*\.(css|png|jpeg|js|gif|html)$/;
export var REGEX_TAG = /(@(?=.{6,}$))(?![_.])(?!.*[_.]{2})[a-zA-Z0-9_.-]+[^_.<\s]/g;
export var REGEX_TAG_ALONE = /(@(?=.{6,}$))(?![_.])(?!.*[_.]{2})[a-zA-Z0-9_.-]+[^<>_.,;]/g;
export var REGEX_SPAN = /<span class="Global__MentionUsernameColor">[\s\S]+?<\/span>/g;
export var REGEX_A = /<a[\s\S]+?<\/a>/g;
export var REGEX_IMG = /<img(?!.*(src=""))[^>]+?>/g;
export var REGEX_MEDIA = /\[MEDIA .+?\]/g;
export var REGEX_MEDIA_URL = /url='.+?'/;
export var REGEX_MEDIA_NAME = /name='.+?'/;

// Style regex
export var REGEX_BOLD = /\*\*[\s\S]+?\*\*/g;
export var REGEX_ITALIC = /{{[\s\S]+?}}/g;
export var REGEX_UNDERLINE = /__[\s\S]+?__/g;
export var REGEX_BI = /\\\\[\s\S]+?\\\\/g;
export var REGEX_BU = /--[\s\S]+?--/g;
export var REGEX_IU = /\|\|[\s\S]+?\|\|/g;
export var REGEX_BIU = /\[\[[\s\S]+?\]\]/g;

// Line break regex
export var REGEX_BR = /<br\s*\/?>/g;
export var REGEX_NEWLINE = /\n\r?/g;
export var REGEX_SPACE = /^\s+/g;
export var findWithRegex = function findWithRegex(regex, text, callback) {
  var matchArr;
  var start = 0;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    var current = matchArr[0];
    callback(start, start + current.length);
  }
};