import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { v4 as uuidv4 } from 'uuid';
import { customLocalStorage, getUserDistinctId } from '@crehana/utils';
export var USER_ACTIVITY_ENDPOINT = '/api/v3/user-activity/';
var showLogs = function showLogs(event, status, payload, message) {
  if (customLocalStorage.getItem('user_activity_logger_enabled')) {
    var startGroup = eval('console.group.bind(console)');
    var endGroup = eval('console.groupEnd.bind(console)');
    var info = eval('console.info.bind(console)');
    startGroup('------ UserActivity track ------');
    info('event:', event);
    if (payload) {
      info('payload:', payload);
    }
    info('status:', status);
    if (message) {
      info('message:', message);
    }
    endGroup();
  }
};
var trackUserActivity = function trackUserActivity(_ref) {
  var event = _ref.event,
    data = _ref.data,
    user = _ref.user,
    nextUrl = _ref.nextUrl,
    jwtToken = _ref.jwtToken;
  return new Promise(function (resolve) {
    var isDesktop = typeof window !== 'undefined' && window.location.href.includes('file://');
    var localStorageUser = customLocalStorage.getItem('creh-tracking-data');
    var userData = user || (localStorageUser ? JSON.parse(localStorageUser) : undefined);
    var generalTrackData = _objectSpread(_objectSpread({
      event_uuid: uuidv4(),
      event_type: event,
      app_platform: (data === null || data === void 0 ? void 0 : data.app_platform) || 'web',
      api_version: 2
    }, userData || {}), {}, {
      distinct_id: getUserDistinctId().distinctId
    });
    var payload = [_objectSpread(_objectSpread({}, generalTrackData), data)];
    var envEndpoint = process.env.NODE_ENV === 'development' ? 'https://qa.creha.co' : 'https://www.crehana.com';
    var completeEndpoint = (data === null || data === void 0 ? void 0 : data.app_platform) === 'desktop' || isDesktop ? "".concat(envEndpoint).concat(USER_ACTIVITY_ENDPOINT) : USER_ACTIVITY_ENDPOINT;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if ((data === null || data === void 0 ? void 0 : data.app_platform) === 'desktop' || isDesktop) {
      headers.append('REQUEST_CURRENT_PLATFORM', 'desktop');
      headers.append('Authorization', "JWT ".concat(jwtToken));
    }

    // Wrap in try-catch to avoid unsupported fetch in some browsers and test environments
    try {
      fetch(completeEndpoint, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(payload)
      }).then(function (res) {
        return res.json();
      }).then(function (res) {
        var _res$;
        var status = res !== null && res !== void 0 && res.success ? 'success' : 'error';
        var message = res !== null && res !== void 0 && res.length ? (_res$ = res[0]) === null || _res$ === void 0 ? void 0 : _res$.message : res === null || res === void 0 ? void 0 : res.message;
        showLogs(event, status, payload, message);
        if (nextUrl) {
          window.location.href = nextUrl;
        } else {
          resolve({
            status: status,
            message: message
          });
        }
      })["catch"](function (err) {
        showLogs(event, 'error', payload, err);
        if (nextUrl) {
          window.location.href = nextUrl;
        } else {
          resolve({
            status: 'error',
            message: err
          });
        }
      });
    } catch (error) {
      showLogs(event, 'error', payload, String(error));
    }
  });
};
export default trackUserActivity;