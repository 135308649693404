import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { breakpointHeights, breakpointWidths } from "../../constants";
var DEFAULT_STATE_VALUES = {
  playerSize: {
    value: 'xxs',
    valueByHeight: 'xxs',
    matches: {
      xxs: true,
      xs: false,
      sm: false,
      md: false,
      lg: false
    }
  },
  playerHeight: 0
};
export var getInitialContextState = function getInitialContextState(_ref) {
  var defaultPlayerSize = _ref.defaultPlayerSize,
    defaultPlayerHeight = _ref.defaultPlayerHeight;
  var playerSizeValue = defaultPlayerSize || DEFAULT_STATE_VALUES.playerSize.value;
  var playerSizeMatches = DEFAULT_STATE_VALUES.playerSize.matches;
  if (playerSizeValue === 'xs') {
    playerSizeMatches = {
      xxs: true,
      xs: true,
      sm: false,
      md: false,
      lg: false
    };
  }
  if (playerSizeValue === 'sm') {
    playerSizeMatches = {
      xxs: true,
      xs: true,
      sm: true,
      md: false,
      lg: false
    };
  }
  if (playerSizeValue === 'md') {
    playerSizeMatches = {
      xxs: true,
      xs: true,
      sm: true,
      md: true,
      lg: false
    };
  }
  if (playerSizeValue === 'lg') {
    playerSizeMatches = {
      xxs: true,
      xs: true,
      sm: true,
      md: true,
      lg: true
    };
  }
  return {
    playerSize: {
      value: playerSizeValue,
      valueByHeight: playerSizeValue,
      matches: playerSizeMatches
    },
    playerHeight: defaultPlayerHeight || DEFAULT_STATE_VALUES.playerHeight
  };
};
export var calculatePlayerSize = function calculatePlayerSize(_ref2) {
  var width = _ref2.width,
    height = _ref2.height,
    defaultPlayerSize = _ref2.defaultPlayerSize,
    defaultPlayerHeight = _ref2.defaultPlayerHeight;
  var playerSizeValue = defaultPlayerSize || DEFAULT_STATE_VALUES.playerSize.value;
  var playerSizeMatches = DEFAULT_STATE_VALUES.playerSize.matches;
  if (width >= breakpointWidths.xs) {
    playerSizeValue = 'xs';
    playerSizeMatches = _objectSpread(_objectSpread({}, playerSizeMatches), {}, {
      xs: true
    });
  }
  if (width >= breakpointWidths.sm) {
    playerSizeValue = 'sm';
    playerSizeMatches = _objectSpread(_objectSpread({}, playerSizeMatches), {}, {
      sm: true
    });
  }
  if (width >= breakpointWidths.md) {
    playerSizeValue = 'md';
    playerSizeMatches = _objectSpread(_objectSpread({}, playerSizeMatches), {}, {
      md: true
    });
  }
  if (width >= breakpointWidths.lg) {
    playerSizeValue = 'lg';
    playerSizeMatches = _objectSpread(_objectSpread({}, playerSizeMatches), {}, {
      lg: true
    });
  }
  var playerSizeValueByHeight = defaultPlayerSize || DEFAULT_STATE_VALUES.playerSize.value;
  if (height >= breakpointHeights.xs) {
    playerSizeValueByHeight = 'xs';
  }
  if (height >= breakpointHeights.sm) {
    playerSizeValueByHeight = 'sm';
  }
  if (height >= breakpointHeights.md) {
    playerSizeValueByHeight = 'md';
  }
  if (height >= breakpointHeights.lg) {
    playerSizeValueByHeight = 'lg';
  }
  return {
    playerSize: {
      value: playerSizeValue,
      valueByHeight: playerSizeValueByHeight,
      matches: playerSizeMatches
    },
    playerHeight: height || defaultPlayerHeight || DEFAULT_STATE_VALUES.playerHeight
  };
};