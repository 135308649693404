import _objectSpread from "@babel/runtime/helpers/objectSpread2";
export var getUIReducerInitialState = function getUIReducerInitialState(_ref) {
  var nextVideo = _ref.nextVideo,
    hiddenNotes = _ref.hiddenNotes;
  return {
    activeMenu: undefined,
    visibleUi: false,
    leaveConfirmation: false,
    autoplayNext: !!(nextVideo !== null && nextVideo !== void 0 && nextVideo.autoplay),
    hiddenNotes: !!hiddenNotes,
    focusedPlayer: true,
    showGeneratedAI: false,
    stateGenerateAI: 'custom',
    sourceAI: 'none'
  };
};
var uiReducer = function uiReducer(state, event) {
  switch (event.type) {
    case 'CHANGE_ACTIVE_MENU':
      return _objectSpread(_objectSpread({}, state), {}, {
        activeMenu: event.activeMenu
      });
    case 'HIDE_ACTIVE_MENU':
      return _objectSpread(_objectSpread({}, state), {}, {
        activeMenu: undefined
      });
    case 'SHOW_UI':
      return _objectSpread(_objectSpread({}, state), {}, {
        visibleUi: true
      });
    case 'HIDE_UI':
      return _objectSpread(_objectSpread({}, state), {}, {
        visibleUi:
        // We cannot hide the ui if a menu is open or a warning is visible
        !!(state.activeMenu || event.currentQualityWarning) ||
        // We cannot hide the ui if the video is paused unless a leave confirmation is visible
        !event.isPlaying && !state.leaveConfirmation
      });
    case 'SHOW_LEAVE_CONFIRMATION':
      return _objectSpread(_objectSpread({}, state), {}, {
        leaveConfirmation: true
      });
    case 'HIDE_LEAVE_CONFIRMATION':
      return _objectSpread(_objectSpread({}, state), {}, {
        leaveConfirmation: false
      });
    case 'TOGGLE_AUTOPLAY_NEXT':
      if (event.onAutoplaySwitch) {
        event.onAutoplaySwitch(!state.autoplayNext);
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        autoplayNext: !state.autoplayNext
      });
    case 'TOGGLE_HIDDEN_NOTES':
      if (event.onHideNotesSwitch) {
        event.onHideNotesSwitch(!state.hiddenNotes);
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        hiddenNotes: !state.hiddenNotes
      });
    case 'CHANGE_PLAYER_FOCUSED_STATE':
      return _objectSpread(_objectSpread({}, state), {}, {
        focusedPlayer: event.focusedPlayer
      });
    case 'TOOGLE_GENERATED_AI':
      return _objectSpread(_objectSpread({}, state), {}, {
        showGeneratedAI: !state.showGeneratedAI,
        stateGenerateAI: 'custom',
        sourceAI: event.paramSource
      });
    case 'CHANGE_STATE_AI':
      return _objectSpread(_objectSpread({}, state), {}, {
        stateGenerateAI: event.paramState
      });
    default:
      return state;
  }
};
export default uiReducer;