import { useEffect } from 'react';
function useOnWindowClick(listener) {
  useEffect(function () {
    if (typeof document !== 'undefined') {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }
    return function () {
      if (typeof document !== 'undefined') {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      }
    };
  }, [listener]);
}
export default useOnWindowClick;