import BottomControls from "./BottomControls";
import ErrorOverlay from "./ErrorOverlay";
import ExternalSubtitles from "./ExternalSubtitles";
import { GenerateNote } from "./GenerateNote";
import Gradient from "./Gradient";
import IdleScreen from "./IdleScreen";
import LeaveConfirmation from "./LeaveConfirmation";
import Logo from "./Logo";
import Menu from "./Menu";
import MiddleControls from "./MiddleControls";
import Overlay from "./Overlay";
import PublicNotes from "./PublicNotes";
import QualityWarning from "./QualityWarning";
import Ratio from "./Ratio";
import SkipButton from "./SkipButtonIndex";
import StyledVideo from "./StyledVideo";
import TopControls from "./TopControls";
export default {
  Ratio: Ratio,
  Gradient: Gradient,
  TopControls: TopControls,
  Overlay: Overlay,
  MiddleControls: MiddleControls,
  BottomControls: BottomControls,
  Menu: Menu,
  QualityWarning: QualityWarning,
  StyledVideo: StyledVideo,
  ExternalSubtitles: ExternalSubtitles,
  PublicNotes: PublicNotes,
  Logo: Logo,
  SkipButton: SkipButton,
  IdleScreen: IdleScreen,
  LeaveConfirmation: LeaveConfirmation,
  ErrorOverlay: ErrorOverlay,
  GenerateNote: GenerateNote
};