// colors.ts

export var transparent = 'transparent';
export var inherit = 'inherit';
export var current = 'currentColor';
export var primaryLight = {
  100: 'var(--color-primary-light-100, #f5f0ff)',
  200: 'var(--color-primary-light-200, #eee6fe)',
  300: 'var(--color-primary-light-300, #9c86f9)',
  400: 'var(--color-primary-light-400, #6f4ef6)',
  500: 'var(--color-primary-light-500, #4b22f4)',
  600: 'var(--color-primary-light-600, #3417aa)',
  700: 'var(--color-primary-light-700, #240e81)'
};
export var primaryDark = {
  100: 'var(--color-primary-dark-100, #eee6fe)',
  200: 'var(--color-primary-dark-200, #dad2fa)',
  300: 'var(--color-primary-dark-300, #c5b9fa)',
  400: 'var(--color-primary-dark-400, #b1a0fa)',
  500: 'var(--color-primary-dark-500, #9c86f9)',
  600: 'var(--color-primary-dark-600, #6f4ef6)',
  700: 'var(--color-primary-dark-700, #3417aa)'
};
export var secondaryLight = {
  100: 'var(--color-secondary-light-100, #d2faf6)',
  200: 'var(--color-secondary-light-200, #c5faf5)',
  300: 'var(--color-secondary-light-300, #49f2e1)',
  400: 'var(--color-secondary-light-400, #39e6d5)',
  500: 'var(--color-secondary-light-500, #0bd4c1)',
  600: 'var(--color-secondary-light-600, #068073)',
  700: 'var(--color-secondary-light-700, #044d46)'
};
export var secondaryDark = {
  100: 'var(--color-secondary-dark-100, #d2faf6)',
  200: 'var(--color-secondary-dark-200, #c5faf5)',
  300: 'var(--color-secondary-dark-300, #49f2e1)',
  400: 'var(--color-secondary-dark-400, #39e6d5)',
  500: 'var(--color-secondary-dark-500, #0bd4c1)',
  600: 'var(--color-secondary-dark-600, #2fbaad)',
  700: 'var(--color-secondary-dark-700, #28a195)'
};
export var neutralLight = {
  100: 'var(--color-neutral-light-100, #ffffff)',
  200: 'var(--color-neutral-light-200, #f7f8fa)',
  300: 'var(--color-neutral-light-300, #f2f3f5)',
  400: 'var(--color-neutral-light-400, #e3e4e5)',
  500: 'var(--color-neutral-light-500, #bbbcbf)',
  600: 'var(--color-neutral-light-600, #a0a2a6)',
  700: 'var(--color-neutral-light-700, #73757a)',
  800: 'var(--color-neutral-light-800, #0f1019)'
};
export var neutralDark = {
  100: 'var(--color-neutral-dark-100, #ffffff)',
  200: 'var(--color-neutral-dark-200, #f0f2ff)',
  300: 'var(--color-neutral-dark-300, #dfe2fd)',
  400: 'var(--color-neutral-dark-400, #b7bad5)',
  500: 'var(--color-neutral-dark-500, #9da0bb)',
  600: 'var(--color-neutral-dark-600, #70738e)',
  700: 'var(--color-neutral-dark-700, #33344f)',
  800: 'var(--color-neutral-dark-800, #050823)'
};
export var alert = {
  100: 'var(--color-alert-100, #fce6ea)',
  200: 'var(--color-alert-200, #ffccd5)',
  300: 'var(--color-alert-300, #e55c75)',
  400: 'var(--color-alert-400, #da435e)',
  500: 'var(--color-alert-500, #c72c43)',
  600: 'var(--color-alert-600, #8c1c30)'
};
export var success = {
  100: 'var(--color-success-100 ,#e5f9f1)',
  200: 'var(--color-success-200 ,#b8fcda)',
  300: 'var(--color-success-300 ,#80e0b4)',
  400: 'var(--color-success-400 ,#34d69b)',
  500: 'var(--color-success-500 ,#00655b)',
  600: 'var(--color-success-600 ,#004a43)'
};
export var warning = {
  100: 'var(--color-warning-100, #fff0cc)',
  200: 'var(--color-warning-200, #ffe4a6)',
  300: 'var(--color-warning-300, #ffd166)',
  400: 'var(--color-warning-400, #ffb200)',
  500: 'var(--color-warning-500, #cc8e00)',
  600: 'var(--color-warning-600, #805706)'
};
export var onHold = {
  100: 'var(--color-on-hold-100, #fcefe9)',
  200: 'var(--color-on-hold-200, #ffceb8)',
  300: 'var(--color-on-hold-300, #f09060)',
  400: 'var(--color-on-hold-400, #e05d1b)',
  500: 'var(--color-on-hold-500, #bf4713)',
  600: 'var(--color-on-hold-600, #8c2b00)'
};
export var info = {
  100: 'var(--color-info-100, #e6f5ff)',
  200: 'var(--color-info-200, #a6dbff)',
  300: 'var(--color-info-300, #40b2ff)',
  400: 'var(--color-info-400, #049bff)',
  500: 'var(--color-info-500, #037ccc)',
  600: 'var(--color-info-600, #025d99)'
};
export var elevationDark = {
  '00': 'var(--color-elevation-dark-00, #050823)',
  '01': 'var(--color-elevation-dark-01, #11142F)',
  '02': 'var(--color-elevation-dark-02, #1A1D38)',
  '03': 'var(--color-elevation-dark-03, #21243F)',
  '04': 'var(--color-elevation-dark-04, #292C47)'
};
export var colors = {
  transparent: transparent,
  inherit: inherit,
  current: current,
  'primary-light': primaryLight,
  'primary-dark': primaryDark,
  'secondary-light': secondaryLight,
  'secondary-dark': secondaryDark,
  'neutral-light': neutralLight,
  'neutral-dark': neutralDark,
  alert: alert,
  success: success,
  warning: warning,
  'on-hold': onHold,
  info: info,
  'elevation-dark': elevationDark
};