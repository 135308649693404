import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useLayoutEffect, useState } from 'react';
function getRect(element) {
  if (element) {
    return element.getBoundingClientRect();
  }
  return {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0
  };
}
var useIsomorphicLayoutEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;
export default function useRect(ref, update) {
  var _useState = useState(getRect()),
    _useState2 = _slicedToArray(_useState, 2),
    rect = _useState2[0],
    setRect = _useState2[1];
  useIsomorphicLayoutEffect(function () {
    var element = ref && ref.current;
    if (!element) return;
    if (update) {
      setRect(getRect(element)); // Update client rect
    }
  }, [ref, update]);
  return rect;
}