import useAudioTracksUpdatedEvent from "./useAudioTracksUpdatedEvent";
import useAudioTrackSwitchedEvent from "./useAudioTrackSwitchedEvent";
import useAudioTrackSwitchingEvent from "./useAudioTrackSwitchingEvent";
import useErrorEvent from "./useErrorEvent";
import useFragLoadedEvent from "./useFragLoadedEvent";
import useLevelLoadedEvent from "./useLevelLoadedEvent";
import useLevelLoadingEvent from "./useLevelLoadingEvent";
import useLevelSwitchedEvent from "./useLevelSwitchedEvent";
import useManifestParsedEvent from "./useManifestParsedEvent";

/**
 * This hook is used to listen to the events fired by hls.js needed to change the player state and/or trigger external listeners.
 * hls.js runtime events reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useHlsEvents = function useHlsEvents(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    defaultAudioLang = _ref.defaultAudioLang,
    onAudioChange = _ref.onAudioChange,
    defaultQuality = _ref.defaultQuality,
    onQualityChange = _ref.onQualityChange,
    withLowQualityWarning = _ref.withLowQualityWarning,
    retriedOnError = _ref.retriedOnError,
    audios = _ref.audios,
    currentQuality = _ref.currentQuality,
    currentQualityWarning = _ref.currentQualityWarning,
    onError = _ref.onError,
    ignoreNetworkErrors = _ref.ignoreNetworkErrors,
    resetErrorState = _ref.resetErrorState;
  useManifestParsedEvent({
    hls: hls,
    dispatch: dispatch,
    defaultQuality: defaultQuality,
    currentQuality: currentQuality
  });
  useFragLoadedEvent({
    hls: hls,
    resetErrorState: resetErrorState
  });
  useLevelSwitchedEvent({
    hls: hls,
    dispatch: dispatch,
    onQualityChange: onQualityChange,
    withLowQualityWarning: withLowQualityWarning,
    currentQuality: currentQuality,
    currentQualityWarning: currentQualityWarning
  });
  useLevelLoadingEvent({
    hls: hls
  });
  useLevelLoadedEvent({
    hls: hls
  });
  useAudioTracksUpdatedEvent({
    hls: hls,
    dispatch: dispatch,
    defaultAudioLang: defaultAudioLang
  });
  useAudioTrackSwitchingEvent({
    hls: hls,
    dispatch: dispatch,
    audios: audios
  });
  useAudioTrackSwitchedEvent({
    hls: hls,
    dispatch: dispatch,
    onAudioChange: onAudioChange
  });
  useErrorEvent({
    hls: hls,
    dispatch: dispatch,
    retriedOnError: retriedOnError,
    onError: onError,
    ignoreNetworkErrors: ignoreNetworkErrors
  });
};
export default useHlsEvents;