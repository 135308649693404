import { useEffect } from 'react';
import Hls from 'hls.js';
import { usePlaybackContext } from "../../Playback";
/**
 * This hook is used to listen to the AUDIO_TRACK_SWITCHED event fired by hls.js,
 * needed to validate that the new audio was loaded and to play the video if it was playing before.
 *
 * AUDIO_TRACK_SWITCHED: fired when an audio track switch actually occurs. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useAudioTrackSwitchedEvent = function useAudioTrackSwitchedEvent(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    onAudioChangeProp = _ref.onAudioChange;
  var _usePlaybackContext = usePlaybackContext(),
    wasPlaying = _usePlaybackContext.wasPlaying,
    play = _usePlaybackContext.play;
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onAudioChanged = function onAudioChanged(_, _ref2) {
      var id = _ref2.id;
      if (wasPlaying) {
        play();
      }
      dispatch({
        type: 'CHANGE_AUDIO_STATE',
        loadingAudio: false
      });
      var audioIndex = Number(id);
      if (hls !== null && hls !== void 0 && hls.audioTracks && onAudioChangeProp) {
        var currentAudioTrack = hls.audioTracks[audioIndex];
        if (currentAudioTrack !== null && currentAudioTrack !== void 0 && currentAudioTrack.lang) {
          onAudioChangeProp(currentAudioTrack.lang);
        }
      }
    };
    hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, onAudioChanged);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.AUDIO_TRACK_SWITCHED, onAudioChanged);
    };
    // Ignoring onAudioChangeProp, assuming it doesn't change after the first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls, dispatch, wasPlaying, play]);
};
export default useAudioTrackSwitchedEvent;