import en from "./localeEn";
import es from "./localeEs";
import pt from "./localePt";
export var localeEn = en;
export var localeEs = es;
export var localePt = pt;
export default {
  en: en,
  es: es,
  pt: pt
};