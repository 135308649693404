import { useEffect } from 'react';
import Hls from 'hls.js';
import { usePlaybackContext } from "../../Playback";
/**
 * This hook is used to listen to the AUDIO_TRACK_SWITCHING event fired by hls.js,
 * needed to validate the current audio when a new audio option is selected.
 *
 * AUDIO_TRACK_SWITCHING: fired when an audio track switching is requested. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useAudioTrackSwitchingEvent = function useAudioTrackSwitchingEvent(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    audios = _ref.audios;
  var _usePlaybackContext = usePlaybackContext(),
    pause = _usePlaybackContext.pause;
  useEffect(function () {
    if (hls && hls.audioTrack >= 0 && audios && audios.length > hls.audioTrack) {
      dispatch({
        type: 'CHANGE_AUDIO_STATE',
        currentAudio: audios[hls.audioTrack]
      });
    }
    // Ignoring audios since we're including the stringified version.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls, dispatch, JSON.stringify(audios)]);
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onAudioChange = function onAudioChange(_, _ref2) {
      var id = _ref2.id;
      var audioIndex = Number(id);
      if (audios && audios.length > audioIndex) {
        pause();
        dispatch({
          type: 'CHANGE_AUDIO_STATE',
          currentAudio: audios[audioIndex],
          loadingAudio: true
        });
      }
    };
    hls.on(Hls.Events.AUDIO_TRACK_SWITCHING, onAudioChange);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.AUDIO_TRACK_SWITCHING, onAudioChange);
    };
    // Ignoring audios since we're including the stringified version.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hls, dispatch, JSON.stringify(audios), pause]);
};
export default useAudioTrackSwitchingEvent;