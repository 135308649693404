import _objectSpread from "@babel/runtime/helpers/objectSpread2";
export var HLS_REDUCER_INITIAL_STATE = {
  retriedOnError: 0,
  playerError: false,
  audios: [],
  currentAudio: undefined,
  loadingAudio: false,
  qualities: undefined,
  currentQuality: undefined,
  realCurrentQualityValue: undefined,
  hasLowQuality: false,
  currentQualityWarning: undefined
};
var hlsReducer = function hlsReducer(state, event) {
  switch (event.type) {
    case 'REGISTER_NEW_RETRY':
      return _objectSpread(_objectSpread({}, state), {}, {
        retriedOnError: state.retriedOnError + 1
      });
    case 'SET_ERROR_STATE':
      return _objectSpread(_objectSpread({}, state), {}, {
        playerError: true
      });
    case 'CHANGE_AUDIO_STATE':
      return _objectSpread(_objectSpread({}, state), event);
    case 'CHANGE_QUALITY_STATE':
      return _objectSpread(_objectSpread({}, state), event);
    case 'HIDE_QUALITY_WARNING':
      return _objectSpread(_objectSpread({}, state), {}, {
        currentQualityWarning: undefined
      });
    case 'RESET_ERROR_STATE':
      return _objectSpread(_objectSpread({}, state), {}, {
        playerError: false
      });
    case 'RESET_STATE':
      return _objectSpread(_objectSpread({}, HLS_REDUCER_INITIAL_STATE), {}, {
        currentAudio: state.currentAudio,
        currentQuality: state.currentQuality
      });
    default:
      return state;
  }
};
export default hlsReducer;