import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
export var normalizeVideoQualities = function normalizeVideoQualities(_ref) {
  var data = _ref.data,
    t = _ref.t,
    defaultQuality = _ref.defaultQuality,
    currentQuality = _ref.currentQuality;
  var autoQuality = {
    label: t('AUTO_QUALITY'),
    value: -1,
    qualityValue: -1
  };
  if (data.levels && data.levels.length) {
    // Remove possible duplicates
    var levels = data.levels.map(function (level, i) {
      return _objectSpread(_objectSpread({}, level), {}, {
        levelIndex: i
      });
    }).filter(function (level, index, self) {
      return self.findIndex(function (l) {
        return l.height === level.height;
      }) === index;
    }).sort(function (a, b) {
      return b.height - a.height;
    });
    // Create the list of quality options (including auto)
    var qualities = [autoQuality].concat(_toConsumableArray(levels.map(function (level) {
      return {
        qualityValue: level.height,
        label: "".concat(level.height, "p"),
        value: level.levelIndex
      };
    }))).map(function (q) {
      if (q.qualityValue >= 1080) {
        return _objectSpread(_objectSpread({}, q), {}, {
          label: "".concat(q.label, " ").concat(t('HD'))
        });
      }
      if (q.qualityValue >= 720) {
        return _objectSpread(_objectSpread({}, q), {}, {
          label: "".concat(q.label, " (").concat(t('RECOMMENDED'), ")")
        });
      }
      return q;
    });
    // Find the initial selected quality
    var defaultQualityValue = defaultQuality ? Number(defaultQuality.replace('p', '')) : undefined;
    var newCurrentQuality = qualities.find(function (q) {
      // Find the current state quality
      if (currentQuality) {
        return q.label === currentQuality.label;
      }
      // Find the default quality
      return q.qualityValue === defaultQualityValue;
    }) ||
    // If neither the current state quality or provided default quality is found, return the auto quality
    autoQuality;
    return {
      qualities: qualities,
      currentQuality: newCurrentQuality
    };
  }
  return {
    qualities: undefined,
    currentQuality: undefined
  };
};