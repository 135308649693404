import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { parseSync } from 'subtitle';
import { LANG_LABEL_BY_KEY } from "../../constants";
var parseSubtitlePlainText = function parseSubtitlePlainText(text) {
  return parseSync(text).filter(function (node) {
    return node.type === 'cue';
  }).map(function (node) {
    var cue = node.data;
    return {
      text: cue.text,
      start: Number(cue.start) / 1000,
      end: Number(cue.end) / 1000
    };
  });
};
export var fetchAndParseSrtSubtitles = function fetchAndParseSrtSubtitles(url) {
  return fetch(url).then(function (res) {
    return res.text();
  }).then(function (text) {
    return parseSubtitlePlainText(text);
  })["catch"](function () {
    return [];
  });
};
export var getDisabledSubtitlesOption = function getDisabledSubtitlesOption(t) {
  return {
    value: 'none',
    label: t('DISABLED_SUBTITLES')
  };
};
var validateAndCreateSubtitleOptions = function validateAndCreateSubtitleOptions(_ref) {
  var options = _ref.options,
    availableSubtitleLanguages = _ref.availableSubtitleLanguages,
    t = _ref.t;
  var availableOptions = _toConsumableArray((availableSubtitleLanguages ? options.filter(function (option) {
    return availableSubtitleLanguages.includes(option.value);
  }) : options).map(function (option) {
    return _objectSpread(_objectSpread({}, option), {}, {
      label: t(LANG_LABEL_BY_KEY[option.value]) || option.label
    });
  }));
  if (!availableOptions.length) {
    return [];
  }
  return [].concat(_toConsumableArray(availableOptions), [getDisabledSubtitlesOption(t)]);
};
export var getSubtitleOptions = function getSubtitleOptions(_ref2) {
  var currentTextTracks = _ref2.currentTextTracks,
    t = _ref2.t,
    videoSubtitles = _ref2.videoSubtitles,
    availableSubtitleLanguages = _ref2.availableSubtitleLanguages,
    defaultSubtitleLang = _ref2.defaultSubtitleLang,
    currentSubtitleLang = _ref2.currentSubtitleLang;
  var subtitleOptions = [];
  var subtitleOptionsType = 'none';
  if (currentTextTracks.length) {
    // If subtitle tracks were included by default from the HLS source,
    // create the subtitle option list from the available tracks
    subtitleOptionsType = 'hlsTextTracks';
    subtitleOptions = validateAndCreateSubtitleOptions({
      options: currentTextTracks.map(function (track) {
        return {
          value: track.language,
          label: track.label
        };
      }),
      t: t,
      availableSubtitleLanguages: availableSubtitleLanguages
    });
  } else if (videoSubtitles !== null && videoSubtitles !== void 0 && videoSubtitles.length) {
    var externalOptions = validateAndCreateSubtitleOptions({
      options: videoSubtitles.map(function (sub) {
        return _objectSpread(_objectSpread({}, sub), {}, {
          value: sub.lang,
          label: sub.label || ''
        });
      }),
      t: t,
      availableSubtitleLanguages: availableSubtitleLanguages
    });
    var externalSupportedOptions = [];
    var externalSrtOptions = [];
    externalOptions.forEach(function (subtitle) {
      if (subtitle.src) {
        var fileType = subtitle.src.split('.').pop();
        if (fileType === 'vtt' || fileType === 'webvtt') {
          externalSupportedOptions.push(subtitle);
        } else {
          externalSrtOptions.push(subtitle);
        }
      }
    });
    if (externalSupportedOptions.length) {
      // Include external vtt or webvtt subtitle tracks if no track is present by default
      subtitleOptionsType = 'externalSupported';
      subtitleOptions = [].concat(externalSupportedOptions, [getDisabledSubtitlesOption(t)]);
    } else if (externalSrtOptions.length) {
      // External srt subtitles only if no other supported track is found for current video.
      // These need to be parsed and included as a custom component
      subtitleOptionsType = 'externalSrt';
      subtitleOptions = [].concat(externalSrtOptions, [getDisabledSubtitlesOption(t)]);
    }
  }
  var defaultSubtitleOption = subtitleOptions && defaultSubtitleLang ? subtitleOptions.find(function (option) {
    return option.value === defaultSubtitleLang;
  }) : undefined;
  var activeSubtitleOption = currentSubtitleLang || defaultSubtitleOption || getDisabledSubtitlesOption(t);
  return {
    subtitleOptions: subtitleOptions,
    subtitleOptionsType: subtitleOptionsType,
    activeSubtitleOption: activeSubtitleOption
  };
};