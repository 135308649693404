import { useEffect } from 'react';
import Hls from 'hls.js';
/**
 * This hook is used to listen to the ERROR event fired by hls.js.
 *
 * ERROR: Identifier for an error event. Reference: https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
 */
var useErrorEvent = function useErrorEvent(_ref) {
  var hls = _ref.hls,
    dispatch = _ref.dispatch,
    retriedOnError = _ref.retriedOnError,
    onError = _ref.onError,
    ignoreNetworkErrors = _ref.ignoreNetworkErrors;
  useEffect(function () {
    if (!hls) {
      return;
    }
    var onHlsError = function onHlsError(_, errorData) {
      if (!errorData.fatal) {
        return;
      }
      if (retriedOnError < 3) {
        switch (errorData.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            hls.startLoad();
            dispatch({
              type: 'REGISTER_NEW_RETRY'
            });
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            if (retriedOnError > 1) {
              // if media errors are still raised after a first retry, this method could be useful to workaround audio codec mismatch.
              hls.swapAudioCodec();
            }
            // try to recover media error
            hls.recoverMediaError();
            dispatch({
              type: 'REGISTER_NEW_RETRY'
            });
            break;
          default:
            // cannot recover
            hls.destroy();
            onError(errorData);
            break;
        }
      } else {
        // do not trigger onError if it is a network error and it should be ignored
        // (ignoring subtitle load errors by default)
        if (errorData.type === Hls.ErrorTypes.NETWORK_ERROR && (ignoreNetworkErrors || errorData.details === Hls.ErrorDetails.SUBTITLE_LOAD_ERROR || errorData.details === Hls.ErrorDetails.SUBTITLE_TRACK_LOAD_TIMEOUT)) {
          return;
        }
        // retry limit reached, destroy and show error overlay
        hls.destroy();
        onError(errorData);
      }
    };
    hls.on(Hls.Events.ERROR, onHlsError);
    return function () {
      if (!hls) {
        return;
      }
      hls.off(Hls.Events.ERROR, onHlsError);
    };
  }, [hls, dispatch, retriedOnError, onError, ignoreNetworkErrors]);
};
export default useErrorEvent;