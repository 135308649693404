import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["addResourceBundle"];
import { useTranslation } from '@crehana/i18n';
import locales from "../i18n";
import { CREHANA_PLAYER_TRANSLATION_KEY } from "../i18n/constants";
var useCrehanaPlayerTranslation = function useCrehanaPlayerTranslation() {
  var _useTranslation = useTranslation(CREHANA_PLAYER_TRANSLATION_KEY),
    addResourceBundle = _useTranslation.addResourceBundle,
    translationContextValue = _objectWithoutProperties(_useTranslation, _excluded);
  addResourceBundle(locales);
  return translationContextValue;
};
export default useCrehanaPlayerTranslation;