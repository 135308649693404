var getCurrentConnectionType = function getCurrentConnectionType() {
  var connection = typeof window !== 'undefined' && window.navigator ?
  // @ts-expect-error (Property 'connection' does not exist on type 'Navigator')
  window.navigator.connection ||
  // @ts-expect-error (Property 'mozConnection' does not exist on type 'Navigator')
  window.navigator.mozConnection ||
  // @ts-expect-error (Property 'webkitConnection' does not exist on type 'Navigator')
  window.navigator.webkitConnection : undefined;

  // read this to understand why we are ignoring this: https://stackoverflow.com/a/69676260
  // @ts-ignore
  return connection === null || connection === void 0 ? void 0 : connection.effectiveType;
};
export default getCurrentConnectionType;