import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { getImgxUrl } from "../LazyImage";
export var getAvatarSrc = function getAvatarSrc(_ref) {
  var srcProp = _ref.src,
    size = _ref.size;
  var baseImgxParams = {
    auto: 'format',
    crop: 'faces',
    dpr: 2
  };
  if (typeof size === 'number') {
    return {
      src: srcProp ? getImgxUrl(srcProp, _objectSpread({
        w: size,
        h: size
      }, baseImgxParams)) : undefined
    };
  }
  var defaultSrc = '';
  var sm = '';
  var md = '';
  var lg = '';
  var sources = {
    "default": defaultSrc,
    sm: sm,
    md: md,
    lg: lg
  };
  if (srcProp) {
    defaultSrc = getImgxUrl(srcProp, _objectSpread({
      w: size.xs,
      h: size.xs
    }, baseImgxParams));
    sm = size.sm ? getImgxUrl(srcProp, _objectSpread({
      w: size.sm,
      h: size.sm
    }, baseImgxParams)) : defaultSrc;
    md = size.md ? getImgxUrl(srcProp, _objectSpread({
      w: size.md,
      h: size.md
    }, baseImgxParams)) : sm;
    lg = size.lg ? getImgxUrl(srcProp, _objectSpread({
      w: size.lg,
      h: size.lg
    }, baseImgxParams)) : md;
    sources = {
      "default": defaultSrc,
      sm: sm,
      md: md,
      lg: lg
    };
  }
  return {
    sources: sources,
    src: lg
  };
};